<template>
	<div class="total">
		<div style="position: absolute;z-index: 9;top:10px;right: 10px;">
			<div class="flex">
				<el-upload class="upload-demo" :action="uploadActionUrl" :on-success="handleAvatarSuccess"
					v-if="CompanyTable.length || TeamTable.length || PersonalTable.length"
					:headers="{Authorization:token}" :data="{source:'user_head'}" :show-file-list="false"
					list-type="picture" multiple>
					<el-button type="primary" size="mini" style="margin: 2px 10px;">上传文件</el-button>
				</el-upload>
				<div
					v-if="post_type==4027 || post_type==4025 || post_type==4024 || post_type==2022 || post_type==4020 || post_type==4018 || post_type==2018">
					<el-button type="primary" size="mini" style="margin: 2px 10px;" v-if="activeName==1"
						@click="editHandle(1,CompanyTable)">
						新建文件夹</el-button>
				</div>
				<el-button type="primary" size="mini" style="margin: 2px 10px;" v-if="activeName==3"
					@click="editHandle(1,PersonalTable)">
					新建文件夹</el-button>

			</div>

		</div>
		<div class="configure">
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="公司云盘" name="1">
					
					<div class="margin-left-lg">
						<span @click="handleClick({name:'1'})" class="cursor">根目录	</span>
						<span class="cursor" v-for="(item,index) of CompanyTable" :key="index"
							@click="folderMessageMore(item,index)"><span
								class="padding-lr-xs">></span>{{item.FolderName}}</span>
					</div>

					<div class="flex flex-wrap-wrap">

						<!-- 文件夹下的所有内容   包括文件夹下面的文件夹 和 文件   
									如果还有文件夹，则还可以操作文件夹，并能点击查看文件夹的下级
									所有文件能够进行删除和下载的操作
									
									CompanyCloudList：[]为公司文件夹下的所有内容
									PersonalCloudList：[]为个人文件夹下的所有内容
						-->
						<div class="text-center margin-lr-lg margin-top cursor textBox" style="width: 90px;"
							v-for="(item,index) of CompanyCloudList" :key="index">
							<a target="_blank" :href="item.FileUrl | previewImage" >
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.png'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-png"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.jpg'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-jpg"></use>
							</svg>
							<svg class="icon BgIco " aria-hidden="true" v-if="item.FileType=='.gif'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-gif"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.txt'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-txt"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.rar'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-rar"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.svg'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-SVG"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.jsx'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-jsx"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true"
								v-if="item.FileType=='.xls' || item.FileType=='.xlsx'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-xls"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.ppt'"
								@contextmenu.stop.prevent="$refs.Menudisplay4.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-ppt"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.zip'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-yasuowenjian"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true"
								v-if="item.FileType=='.docx'  || item.FileType=='.doc'">
								<use xlink:href="#icon-file-2"></use>
							</svg>
							</a>
							<!-- 文件夹的图标 -->
							<svg class="icon BgIcon  text-yellow" aria-hidden="true" v-if="item.FolderType==1"
								@dblclick="folderMessageMore(item)"
								@contextmenu.stop.prevent="$refs.Menudisplay1.showMenu($event,item)">
								<use xlink:href="#icon-wenjian3"></use>
							</svg>
							<div class="text-black nameStyle" style="font-size: 12px;">
								{{item.FolderName}} {{item.FileName}}
							</div>
						</div>

						<!-- 文件夹的操作 -->
						<e-vue-contextmenu ref="Menudisplay1" @ctx-show="show">
							<div class="padding-tb-xs padding-left text cursor" @click="changeFolderMessage">重命名</div>
							<div class="padding-tb-xs padding-left text cursor" @click="Delete">删除</div>
						</e-vue-contextmenu>
						<!-- 文件的操作 -->
						<e-vue-contextmenu ref="Menudisplay4" @ctx-show="showFolder">
							<div class="padding-tb-xs padding-left text cursor" @click="DeleteFolder(1)">删除</div>
						</e-vue-contextmenu>
					</div>
				</el-tab-pane>

				<el-tab-pane label="个人云盘" name="3">
					<div class="margin-left-lg">
						<span @click="handleClick({name:'3'})" class="cursor">根目录</span>
						<span class="cursor" v-for="(item,index) of PersonalTable" :key="index"
						@click="folderMessageMore(item,index)"><span
								class="padding-lr-xs">/</span>{{item.FolderName}}</span>
					</div>
					<div class="flex flex-wrap-wrap">
						<div class="text-center margin-lr-lg margin-top cursor textBox" style="width: 80px;"
							v-for="(item,index) of PersonalCloudList" :key="index">
							<a target="_blank" :href="item.FileUrl | previewImage" >
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.png'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-png"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.jpg'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-jpg"></use>
							</svg>
							<svg class="icon BgIco " aria-hidden="true" v-if="item.FileType=='.gif'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-gif"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.txt'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-txt"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.rar'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-rar"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.svg'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-SVG"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.jsx'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-jsx"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.zip'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-yasuowenjian"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true"
								v-if="item.FileType=='.xls' || item.FileType=='.xlsx'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-xls"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true" v-if="item.FileType=='.ppt'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-wenjianleixing-ppt"></use>
							</svg>
							<svg class="icon BgIcon" aria-hidden="true"
								v-if="item.FileType=='.docx'  || item.FileType=='.doc'" @contextmenu.stop.prevent="$refs.Menudisplay2.showMenu($event,item)">
								<use xlink:href="#icon-file-2"></use>
							</svg>
							</a>
							<svg class="icon BgIcon  text-yellow" aria-hidden="true" v-if="item.FolderType==3"
								@dblclick="folderMessageMore(item)"
								@contextmenu.stop.prevent="$refs.Menudisplay3.showMenu($event,item)">
								<use xlink:href="#icon-wenjian3"></use>
							</svg>
							
							<div class="text-black nameStyle" style="font-size: 12px;">
								{{item.FolderName}} {{item.FileName}}
							</div>
							
						</div>
						<e-vue-contextmenu ref="Menudisplay3" @ctx-show="show">
							<div class="padding-tb-xs padding-left text cursor" @click="changeFolderMessage">重命名</div>
							<div class="padding-tb-xs padding-left text cursor" @click="Delete">删除</div>
						</e-vue-contextmenu>
						<e-vue-contextmenu ref="Menudisplay2" @ctx-show="showFolder">
							<div class="padding-tb-xs padding-left text cursor" @click="DeleteFolder(2)">删除</div>
						</e-vue-contextmenu>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<AddFile :Visible="editVisible" :message="massage" @closepop="closeEditPop"></AddFile>
	</div>
</template>
<script>
	import {baseURL}  from '@/utils/config'
	import AddFile from './components/addFile.vue'
	import {
		getToken
	} from '@/utils/auth'

	export default {
		components: {
			AddFile,

		},
		data() {
			return {
				Url:'http://47.92.245.51:8009',
				token: getToken() || -1,
				activeName: '1',
				input: '',
				editVisible: false,
				massage: {
					type: '',
					num: 0,
					detail: {},
					title: ''
				},
				uploadFiles: {
					FolderGuid: "",
					FileName: "",
					FileSize: 0,
					FileType: "",
					FileUrl: ""
				},
				CompanyCloudList: [],
				PersonalCloudList: [],
				CompanyTable: [],
				TeamTable: [],
				PersonalTable: [],
				post_type: 0, //账号的岗位
				folderMessage: {}, //右击文件夹获取的文件夹具体内容
				FolderDetails: {}, //文件的具体内容
				listQuery: {
					FolderGuid: "",
					FolderType: 1,
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						Field: "id",
						Type: 0
					}]
				},
			};
		},
		created() {
			this.post_type = this.$store.getters.getUserInfo.post_type
			this.getList()
		},
		methods: {
			getList() {
				if (this.listQuery.FolderType == 1) {
					//查询的是公司文件夹
					this.getSelectFolderMessage(this.listQuery).then(res => {
						this.CompanyCloudList = res.data.rows
					})
				} else {
					//查询个人的文件夹
					this.getSelectFolderMessage(this.listQuery).then(res => {
						this.PersonalCloudList = res.data.rows
					})
				}
			},
			//查询所有的文件夹
			getSelectFolderMessage(data) {
				return new Promise((resolve, reject) => {
					this.API.cloudDiskSelectFolder(data).then(res => {
						resolve(res)
					})
				})
			},

			handleClick(val) {
				this.CompanyTable = []
				this.TeamTable = []
				this.PersonalTable = []
				this.listQuery.FolderType = val.name
				if (val.name == 1) {
					this.getSelectFolderMessage(this.listQuery).then(res => {
						this.CompanyCloudList = res.data.rows
					})
				} else {
					this.getSelectFolderMessage(this.listQuery).then(res => {
						this.PersonalCloudList = res.data.rows
					})
				}
			},
			handleAvatarSuccess(res, file) {
				let Foldermessage = {}
				if (this.CompanyTable.length != 0) {
					Foldermessage = this.CompanyTable.pop()
				} else if (this.TeamTable.length != 0) {
					Foldermessage = this.TeamTable.pop()
				} else if (this.PersonalTable.length != 0) {
					Foldermessage = this.PersonalTable.pop()
				}
				this.uploadFiles.FolderGuid = Foldermessage.FolderGuid
				this.uploadFiles.FileName = res.data.Name;
				this.uploadFiles.FileSize = res.data.Size;
				this.uploadFiles.FileUrl = res.data.src;
				this.uploadFiles.FileType = res.data.FileType;
				console.log(Foldermessage)
				this.API.cloudDiskAddFile(this.uploadFiles).then(res => {
					if (res.code == 200) {
						this.$message.success("上传成功")
						this.folderMessageMore(Foldermessage)
					}
				})
			},
			closeEditPop() {
				this.editVisible = false,
					this.getList()
			},
			editHandle(val, row) {
				let detail = row
				this.editVisible = true;
				this.massage.type = this.activeName
				this.massage.num = val
				if (val == 2) {
					this.massage.detail = detail
					this.massage.title = "重命名"
				} else {
					this.massage.detail = detail
					this.massage.title = "新建文件夹"
				}
			},
			//删除文件夹
			Delete() {
				this.$confirm('确定将此文件夹永久删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let list = {
						FolderId: this.folderMessage.id
					}
					this.API.cloudDiskDeleteFolder(list).then(res => {
						if (res.code == 200) {
							this.$message.success("删除成功")
							let list = this.CompanyTable.pop()
							this.folderMessageMore(list)
						}
					})
				}).catch(() => {});
			},
			//右击显示
			show(data) {
				//文件夹的信息
				this.folderMessage = data
			},
			//右击文件
			showFolder(data) {
				//文件的信息
				this.FolderDetails = data
				console.log(data)
			},
			//删除文件
			DeleteFolder(index) {
				this.$confirm('确定将此文件永久删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let list = {
						FileGuid: this.FolderDetails.FileGuid
					}
					this.API.cloudDiskDeleteFile(list).then(res => {
						if (res.code == 200) {
							debugger
							this.$message.success("删除成功")
							if(index==1){
								let lists = this.CompanyTable.pop()
								this.folderMessageMore(lists)
							}else{
								let lists = this.PersonalTable.pop()
								this.folderMessageMore(lists)
							}
						}
					})
				}).catch(() => {

				});
			},
			//修改文件夹的信息
			changeFolderMessage() {
				this.editHandle(2, this.folderMessage)
			},
			//查看文件夹下级的文件夹
			getSelectChildFolder(data) {
				return new Promise((resolve, reject) => {
					this.API.cloudDiskSelectChildFolder(data).then(res => {
						resolve(res.data.rows)
					})
				})
			},
			//查看文件夹详情
			folderMessageMore(row, index) {
				let listQueryFile = {
					FolderGuid: row.FolderGuid,
					FolderType: 1,
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						Field: "id",
						Type: 0
					}]
				}
				let listQuerys = {
					id: row.id,
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						Field: "id",
						Type: 0
					}]
				}
				this.CompanyCloudList = []
				this.TeamCloudList = []
				this.PersonalCloudList = []
				if (row.FolderType == 1 && index != undefined) {
					this.CompanyTable = this.CompanyTable.slice(0, index + 1)
				} else if (row.FolderType == 1) {
					this.CompanyTable.push(row)
					this.CompanyTable = [...new Set(this.CompanyTable)]
				}
				if (row.FolderType == 3 && index != undefined) {
					this.PersonalTable = this.PersonalTable.slice(0, index + 1)
				} else if (row.FolderType == 3) {
					this.PersonalTable.push(row)
					this.PersonalTable = [...new Set(this.PersonalTable)]
				}
				if (row.FolderType == 1) {
					//查询文件
					this.getSelectChildFolder(listQuerys).then(res => {
						let list = res
						//具体的文件
						this.API.cloudDiskSelectFile(listQueryFile).then(res1 => {
							this.CompanyCloudList = list.concat(res1.data.rows)
							console.log(this.CompanyCloudList)
						})
					})
				} else if (row.FolderType == 3) {
					this.getSelectChildFolder(listQuerys).then(res => {
						let list = res
						this.API.cloudDiskSelectFile(listQueryFile).then(res1 => {
							this.PersonalCloudList = list.concat(res1.data.rows)
						})
					})
				}
			}
		}
	};
</script>

<style scoped>
	.icon {
		width: 45px;
		height: 45px;
		vertical-align: -0.15em;
		fill: currentColor;
		overflow: hidden;
	}

	.text:hover {
		background-color: #f0f0f0;
		color: #333333;
	}

	.textBox:active {
		opacity: 0.5;
	}

	.nameStyle {
		-webkit-line-clamp: 2;
		word-break: keep-all;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.configure {
		width: 100%;
		background-color: #fff;
	}
</style>
