<template>
	<el-dialog :title="message.title" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="25%">
		<div  v-loading="loading">
			<el-input placeholder="新建文件夹" v-model="listQuery.FolderName "></el-input>
			<div class="flex justify-content-flexEnd margin-top">
				<el-button type="primary" @click="AddEditFolde" v-preventReClick="500">确定</el-button>
				<el-button class="myClient-left-30" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			},
			message: {
				type: Object,
				default:()=>{
					return {}
				},
			}
		},
		data() {
			return {
				dialogVisible: false,
				loading:false,
				listQuery: {
					FolderName: "",
					ParentId: 0,
					FolderType: "",
				},
			}
		},
		watch: {
			Visible(val) {
				this.listQuery={
					FolderName: "",
					ParentId: 0,
					FolderType: "",
				}
				console.log(this.message)
				this.dialogVisible = val;
				this.listQuery.FolderType= this.message.type
				if(this.message.num==2){
					this.listQuery.FolderName=this.message.detail.FolderName
					this.listQuery.ParentId=this.message.detail.ParentId
					this.listQuery.id=this.message.detail.id
					this.listQuery.FolderType=this.message.detail.FolderType
				}else if(this.message.num==1 && this.message.detail.length){
					let list=this.message.detail.pop()
					this.listQuery.ParentId=list.id
				}
			},
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},
			AddEditFolde() { 
				this.loading=true
				this.API.cloudDiskEditFolder(this.listQuery).then(res => {
					if (res.code == 200) {
						this.$message.success("操作成功")
						this.loading=false
						this.close()
					}
				})
			}
		}
	}
</script>

<style>
</style>
